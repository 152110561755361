<template>
  <el-card class="body" style="margin-top: 10px;">
    <div class="itemPut">
      <div style="margin: 20px"></div>
      <el-form
        :model="levelForm"
        label-width="150px"
        label-position="right"
        :rules="rules"
        ref="inserform"
      >
        <el-form-item label="会员等级名称：" prop="name">
          <el-input v-model.trim="levelForm.name"></el-input>
        </el-form-item>
        <el-form-item label="会员等级备注：" prop="remark">
          <el-input type="textarea" v-model="levelForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <router-link to="/setting/member/level/list">
          <el-button>取 消</el-button>
        </router-link>
        <el-button
          type="primary"
          style="margin-left: 50px"
          @click="levelEdit('inserform')"
          >提 交</el-button
        >
      </div>
    </div>
  </el-card>
</template>
<script>
import { levelEdit } from "@/api/member";
export default {
  data() {
    return {
      levelForm: {},
      rules: {
        name: [
          { required: true, message: "请填写会员等级名", trigger: "blur" },
        ],
        remark: [{ required: true, message: "请填写备注", trigger: "blur" }],
      },
    };
  },
  created() {
    this.list();
  },
  methods: {
    //获取详情
    list() {
      let row = this.$route.query;
      this.levelForm = row;
    },
    // 编辑会员等级
    levelEdit(inserform) {
      let self = this;
      self.$refs[inserform].validate((res) => {
        if (res) {
          levelEdit(self.levelForm).then(() => {
            this.$router.push("/setting/member/level/list");
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/css/overall.less";
</style>